/*Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,700;0,900;1,300&family=Sanchez&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  margin: 0;
  padding:0 ;
  display: block;
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-family:'Poppins', sans-serif;
  overflow-x:hidden;
  position: relative;
  background-color: black;
}

.vertical-line
{
  border-left: 5px solid #f8d407;
  height: 35vh;
  position: absolute;
  left: 20%;
  justify-content: center;
  top: 28%;
}

main {
  background-image: url('Images/Backgroundpicture.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.content {
  text-align: center;
  color: white;
  height: fit-content;
  margin: 1.5em;
  padding:2em;
  width: 60%;
  border-left:  3px solid #f8d407;
}

.content h1{
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 3.4em;
  line-height: 1em;
}

.content h2{
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-weight: 300;
  font-size: 1.2em;
}

.header-list{
  list-style-type: none;
  overflow: hidden;
  background-color: #000000;
  display: flex;
  justify-content: space-evenly;
  margin:0;
  padding:0;
  align-items: center;
}

.navlist{
  display:flex;
  flex-direction: row;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-weight: 550;

}

li {
  float: left;
}

li a {
  display: block;
  color:white;
  text-align: center;
  padding: 0;
  margin: .5em .2em;
  width: 100%;
  text-decoration: none;
  transition: all ease-in-out .3s;
}


li a:hover {
  border: 3px solid yellow;
  padding: .4em .6em;
  margin: 0;
  background-color: #f8d407;
  color:#000;
  transition: all ease-in-out .3s
  ;
}

/*li a.active {
  border: 1px solid yellow;
  padding: 12px 14px; }*/


.logo
{
  position: relative;
  width: 280px;
  height: 55px;
}
.navlist ul
{
  display: flex;
  flex-direction: row;
  width: 100%;
}

header{
    background-color: black;
    display: flex;
    height: 60px;
    align-items: center;
  }

  .contact-info {
    position: relative;
    top: 0;
    right: 10px;
    padding: 1px 1px;
    color:white;
    z-index: 1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 550;

  }

#hamburger{
  display: none;
}

/* What we do*/
.container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  font-family:'Poppins', sans-serif;
  font-weight: 300;
  height: 100%;
}

.description {
  width: 100%;
  background-color:black;
  color: white;
  position: relative;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family:'Poppins', sans-serif;
  font-weight: 300;
  padding: 1em;
}

.description h1{
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
    font-size: 1.5em;
}

.what-we-do-list {
  margin: 0 auto;
  font-size: 16px;
  width: 60%;
}

.what-we-do-list li {
  font-size: 18px;
  margin-bottom: 10px;

}

.stock-image {
  position: relative;
  width: 100%;
}

.stock-image img {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

/*About Us*/
.aboutUs-container{
  display: flex;
  justify-content: space-between;

}
.about{
  width: 50%;
  background-color:black;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.about h1{
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 2.1em;
}


/* Mission & Vision*/
.vision{
  background-color: #925d2e;
}

.mission{
  background-color: #DFC01B;
}

.missionVisionBody{
  background-image: url('./Images/Mission&VisionBg.png'); 
  background-size: cover; 
  background-position: center; 
  padding: 2em;
}

.missionVision-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; 
  height: 100vh; 
  width: 100%;
}
.box h2{
  margin-bottom: 1em;
  font-weight: 600;
  font-size: 1.5em;
}


.box {
  width: 30%; 
  padding: 4em;
  margin: 2em;
  text-align: center;
  border: 1px solid #44391b;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  border-radius: 4px;

}
/* Our Products*/
.product-body{
  background-color: #885f36;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;

}
.products-container h1{
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 2.5em;

}


.products-container {
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}

.products-container .row {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 1em ;
  margin:0;
}

.product-card {
  flex: 1;
  border: 0px solid black;
  margin: .5em 1.5em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: black;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 1);
  transition: transform 0.3s;
}

.product-card:hover {
transform: scale(1.05);
}  

.product-card img {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;;
  object-fit: cover;
}
.product-card p {
  background-color: #DFC01B;
  color: #000;
  flex-grow: 1;
  padding: 1.5em;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6em;
  max-height: 8em;
}

.product-card:nth-child(2) p {
  background-color: #000;
  color: white;
}

.view-more-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #DFC01B;
  color: black;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

.view-more-btn a{
  text-decoration: none;
  color: black;
}

/*Contanct Us*/
.contact-body{
  background-image: url('./Images/Contact_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  width: 100%;
  padding: 5em 0 ;
}
.backImage {
position: absolute;
 z-index: -1;
}
.contactUs-container {
  color: white;
  text-align: center;
  position: relative;
  z-index: 1;
  margin: 5em auto;

}
.contactUs-container h1 {
  font-size: 2.5em;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-top: 20px;
}
.inquire-btn {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #DFC01B;
  border: none;
  color: #ffffff;
  cursor: pointer;
}
.inquire-btn a {
  text-decoration: none;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
.inquire-text {
  font-size: 18px;
  margin-top: 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
/* About us*/
._container {
  display: flex;
  justify-content:center;
  width: 100%;
  flex-direction: row;
}
.about {
  width: 100%;
  background-color:black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 3em;
}

.about button {
  padding: 1em;
  margin:0 .5em;
  background-color: #DFC01B;
  border: none;
  width: fit-content;
  text-decoration: none;
  color: black;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.about h2 {
  font-size: 2em;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  }


.Pindiwe-image {
  width: 100%;
  display: flex;
  padding: 3em;
  background-color: #000;
  border-radius: 0;
}

.Pindiwe-image img {
  max-width: 100%;
  width: auto;
  margin: 0 0 0 auto;
  height: 100%;
  object-fit: contain;
}

/*Footer*/

footer {
  background-color: #DFC01B;
  color: #0c0c09;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 1em;
}
.footer-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

}

footer hr {
  border: .5px solid black;
  width: 100%;
}

.footer-info {
 display: flex;
 flex-direction: row;
 width: 100%;
 justify-content:space-evenly;
 align-items: stretch;
}

.footer-logo{
  position: relative;
  width: 18em;
  height: 55px;

}
.footer-logo img{
  width: 100%;
}

.address {
  padding: 0.5em;
}

.address img{
  width: 2.5em;
}
.address p {
  margin-top: 8px;
  margin-bottom: 0px;
 }

.separator {
  border: 1px solid #000;
  margin: 10px 0;
}
.info-link {
  width: 50%;
}

.footer-links {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}


.footer-links li {
  display: inline;
  padding: 1em .2em;
  color: black !important;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;

}

.contact-details{
  display: flex;
}

.copyright {
  margin-top: auto;
}


/*About US*/
._container.aboutUs-container {
  line-height: 1.4em;
}
._container.aboutUs-container .Pindiwe-image{
  height: 85vh;
  padding:.5em 2em;
  line-height: 1.4em;

}
._container.aboutUs-container .Pindiwe-image img{
  max-width: 500px;
  width: 100%;
  padding:.5em 2em;
  line-height: 1.4em;
  margin: auto;
}
._container.aboutUs-container .about{
  padding:.5em 2em;
  line-height: 1.4em;
}
._container.aboutUs-container .about .about-description{
  font-size: 1em;
 line-height: 1.4em;
}
.heading-video .h1{
 
  font-size: 1px;
}
.heading-video .h2 {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  color:#ffffff;
  display: flex;
  justify-content: center;
}
.Manufacturing{
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: row;
}
.video-container video{
  max-width: 100%;
  width: auto;
  margin: 0 0 0 auto;
  height: 100%;
  object-fit: contain;
}
.video-description{
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 3em;
}
.video-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 2em;
 
}

.video-container video {
  width: 100%;
  display: block;
  flex-direction: column;
  justify-content: center;
  background-color: black;
}

.video-description {
  margin-top: 20px;
  font-size: 1em;
  line-height: 1.4em;
}



.video-container video::-webkit-media-controls-panel {
  justify-content: center;
}