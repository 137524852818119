.title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding:.5em 0 0 0;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.3em;
    padding: 1em;
    
    
}

.contact-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 80px;

}

.phone,
.email {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phone-icon,
.email-icon {
  background-color: #f8d407;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 40px;
}

.phone-number,
.email-info {
  background-color: #f0f0f0;
  padding: 10px 20px;
  text-align: center;
  border-radius: 10px;
}


.contact h3 {
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;

    font-size: 1.5em;
    margin-bottom: 10px;
    padding-left: 16px;
}



/* Mobile Phones */
@media only screen and (max-width: 768px) {
  .contactUs {
    padding: 10px; 
  }
    .contact-container {
        display: flex;
        justify-content: center;
        flex-direction:column;
        padding:.5em;
        padding-bottom: 80px;
        margin-bottom: -50px;
        overflow: hidden;
        
      }
    }
  
    .phone,
    .email {
      margin-bottom: 0;
    }
  