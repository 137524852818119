/* Mobile Phone */
@media only screen and (max-width: 768px) {
    main {
      height: 80vh; 
    }
    .navlist ul {
        flex-direction: column;
        align-items: center;
      }
      body {
        background-color: white; 
        margin: 0; 
      }
      .navlist {
        position: fixed;
        top: 0;
        margin-top: 3.4em;
        left: 0;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        bottom:0;
        background: #000000;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        transition: all .5s ease-in;
        z-index:10;
       
      }
    
      li a:hover {
        border-bottom: 3px solid #967e4c; 
        
      }
        
      .contact-info {
        display: none;
      }
     .navlist.closed{
        left: -150%;
       transition: all .4s ease-in;
        
      }
    
    #hamburger {
      display: block;
      margin-top: 0px; 
      padding-left: auto;
      cursor: pointer;
      padding:1.5em 1em;
    }
    
    header{
      background-color: black;
      display: flex;
      justify-content: space-between;
      position: fixed;
      width:100% ;
      z-index: 10;
    }
    
    li {
      float: none;
      width: 100%;
      margin-right: 6px;
    
    }
    }
    /* Tablets */
    @media only screen and (max-width: 768px) {
      .navlist {
        flex-direction: column;
        align-items: center;
      }
      .content {
        text-align: center;
        color: white;
        height: fit-content;
        margin: 1.5em;
        padding:1em;
        width: 70%;
        border-left:  3px solid #f8d407;
      }
      
      .content h1{
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        font-size: 2.5em;
      }
      
      .content h2{
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: 300;
        font-size: 1em;
        line-height: 1.9em;
      }
      .container {
        position: relative;;
      }
      .description {
        width: 100%;
        /*background-color:rgba(0, 0, 0, 0.785);*/
        color: white;
        position: relative;
        z-index: 1;
        padding: 2em;
        backdrop-filter: blur(2px);
      }
      
      .stock-image {
        z-index: 0;
        position:absolute;
        width: 100%;
      }
      li {
        float: none;
      }
      .box {
        width: 80%; 
      }

      .missionVisionBody{
        padding: 3em;
      }
      .missionVision-container {
        flex-direction: column; 
        align-items: center; 
      
      }
  
      .products-container h1 {
        font-family: 'Poppins', sans-serif;
      font-weight: 700;
      font-size: 2.5em;

        padding:1em;
      }
      .products-container .row{
        display: flex;
        justify-content: center;
        flex-direction:column;
        padding:.5em;
      }
      .row .product-card {
        margin:1em auto;
        max-width: 450px;
      }

      .footer-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content:stretch;
        align-items: stretch;
      }
      .info-link {
        width: 100%;
      }
      .footer-info {
        font-size: .9em;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content:stretch;
        align-items: stretch;
      }
      ._container {
        display: flex;
        justify-content:center;
        flex-direction: column;
        width: 100%;
      }
  
      .Pindiwe-image {
        width: 100%;
        display: flex;
        padding: .5em; 
        background-color: #000;
        
      }
      .Pindiwe-image img {
        max-width: 50%;
        width: auto;
        margin: auto;
        padding:3em;
        height: 100%;
        border-radius: 50%;
      }
      .about {
       justify-content: center;
       padding: 6em 0;
       height: fit-content;
       text-align: center;
      }
      .about h2,.about a {
        text-align: center;
      }
      ._container.aboutUs-container .Pindiwe-image {
        width: 100%;
        display: flex;
        /*padding: 2em; */
        background-color: #000;
        height: 100%;
  
      }
      ._container.aboutUs-container .Pindiwe-image img {
        max-width: 60%;
        width: auto;
        margin: 1em auto 0 auto;
        padding:3em;
        height: 100%;
        
  
      }
      ._container.aboutUs-container .about { 
        height: fit-content;
        padding: 1em 0 5em 0;
      }
     ._container.aboutUs-container .about-description{
          padding:.5em 2em;
          line-height: 1.4em;
    }

        
    .heading-video{
      display: none;
    }

    .Manufacturing {
      display: flex;
      justify-content:center;
      flex-direction: column;
      width: 100%;
      background-color: black;
    }

    .video-container {
      width: 100%;
      display: flex;
      padding: .5em; 
      background-color: #000;
      
    }
    .video-container video  {
      max-width: 80%;
      width: auto;
      margin: auto;
      padding:3em;
      height: 100%;
    
    }
    .video-description {
     justify-content: center;
     padding: 1em 0;
     height: fit-content;
     text-align: center;
    }
    
  
    @media only screen and (min-width: 1024px) {
    .header-list {
      justify-content: center;
      }
  
    .header-list li {
      padding:.5em 2em;
    }
    }
  
  }